import * as React from "react"
import {graphql, useStaticQuery} from "gatsby";
import HumaneHeroesNavigation from "../components/education/humaneHeroesNavigation";
import ImageLink from "../components/global/imageLink";
import InnerLayout from "../components/global/innerLayout";
import LargeCarousel from "../components/global/largeCarousel";
import PageTitle from "../components/global/pageTitle";
import parse from "html-react-parser"
import "./ah.scss"

const HumaneHeroesOverviewPage = () => {

    const data = useStaticQuery(graphql`
        {
          craft {
            page: entry(section: "humaneHeroesOverview") {
              ... on Craft_humaneHeroesOverview_humaneHeroesOverview_Entry {
                id
                uri
                title
                pageTitle
                educationOverviewHeroCarousel {
                  ... on Craft_educationOverviewHeroCarousel_slide_BlockType {
                    image {
                      url(transform: "heroImageTransform")
                      ... on Craft_websiteEducationGeneral_Asset {
                        altText
                      }
                    }
                    imageLink {
                      linkUrl
                      openInNewTab
                    }
                  }
                }
                section2ContentTitle
                contentExtendedButtonsImgOptionsEducation1
                humaneHeroesFeaturedBooks {
                  ... on Craft_humaneHeroesBookPage_default_Entry {
                    key: id
                    url
                    title
                    contentDefaultHtmlEducation1
                    educationBookFrontCover {
                        url(transform:"bookCoverMedium")
                         ... on Craft_websiteEducationGeneral_Asset {
                            altText
                      }
                    }
                  }
                }
                contentExtendedButtonsImgOptionsEducation2
                humaneHeroesMiddleSchoolBooks {
                  ... on Craft_humaneHeroesMiddleSchoolBooks_middleSchoolVolumes_BlockType {
                    volumesText
                    volumes {
                      ... on Craft_humaneHeroesBookPage_default_Entry {
                        key: id
                        url
                        title
                        educationBookFrontCover {
                            url(transform:"bookCoverMedium")
                             ... on Craft_websiteEducationGeneral_Asset {
                                altText
                          }
                        }
                      }
                    }
                  }
                }
                seomatic {
                  metaTitleContainer
                  metaTagContainer
                  metaLinkContainer
                  metaScriptContainer
                  metaJsonLdContainer
                  metaSiteVarsContainer
                  frontendTemplateContainer
                }
              }
            }
          }
        }
  `)
    
    const getBooks = () => {
        return data.craft.page.humaneHeroesFeaturedBooks.map(book => (
            <div className="humane-post">
                    <div className="humane-post-img">
                        {book.educationBookFrontCover && <ImageLink src={book.educationBookFrontCover[0].url} alt={book.educationBookFrontCover[0].altText} to={book.url} openInNewTab={false} />}
                    </div>
                    <a href={book.url} className="download-link">{book.title}</a>
                    {parse(book.contentDefaultHtmlEducation1)}
                </div>
        ))
    }

    const getMiddleSchoolBooks = () => {
        return data.craft.page.humaneHeroesMiddleSchoolBooks.map(book => {
            return book.volumes.map(volume => (
                <div className="content-post">
                    {volume.educationBookFrontCover && <ImageLink src={volume.educationBookFrontCover[0].url}
                                                                  alt={volume.educationBookFrontCover[0].altText}
                                                                  to={volume.url} openInNewTab={false}/>}
                    <a href={volume.url} className="btn btn-primary">View Book</a>
                </div>
            ))
        })
    }

    return (
        <InnerLayout seomatic={data.craft.page.seomatic} entryId={data.craft.page.id}>
            <div className="midd humane-heroes-page">
                <PageTitle title={data.craft.page.pageTitle} className="education"/>

                <div className="container-small">
                    <LargeCarousel slides={data.craft.page.educationOverviewHeroCarousel} items={1} loop={true} autoplay={true} autoplayTimeout={5000} autoplayHoverPause={true} />

                    <div className="content-colum-row">
                        <div className="sidebar">
                            <HumaneHeroesNavigation active={data.craft.page.uri}/>
                            <div className="side-hero-logo"><img src="images/left-heroes-logo.png" alt="" /></div>
                        </div>
                        <div className="content-right">

                            <h3>{data.craft.page.section2ContentTitle}</h3>

                            <div className="humane-book-list">
                                {getBooks()}
                            </div>

                            <div className="hero-content-block">
                                {parse(data.craft.page.contentExtendedButtonsImgOptionsEducation1)}
                            </div>

                            <div className="hero-content-post">

                                <div className="content-post-row">
                                    {getMiddleSchoolBooks()}
                                    <div className="content-post content-post-vc">
                                        <h4>{data.craft.page.humaneHeroesMiddleSchoolBooks[0].volumesText}</h4>
                                    </div>
                                </div>

                            </div>

                            <div className="hero-content-block">
                                {parse(data.craft.page.contentExtendedButtonsImgOptionsEducation2)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </InnerLayout>
    )
}

export default HumaneHeroesOverviewPage
